<template>
  <section id="hero">
    <!----
    <v-img
      src="https://picsum.photos/1920/1080?random" -> original
      :src="getBackgroundPictureName()"  -> NEW WAY TO  MAKE  IT CONTROLLED BY US USING PICTURES IN PUBLIC/BACKGROUND
    >
    -->
    <base-img
      :min-height="minHeight"
      :src="getBackgroundPicture()"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
    >
      <v-container class="fill-height px-4 py-12">
        <!--------     AUTHENTICATOR DIALOG    ----------------------------------------->
        <v-dialog
          v-model="dialogAuth"
          persistent
        >
          <v-card>
            <v-card-title>
              <base-info-card :title="$t('hero.authenticator')" />
            </v-card-title>
            <kdw-auth-component
              :sign-up-type="ENDUSER"
              :navigate-next="navigateNext"
            />
            <kdw-btn
              text
              @click="cancelQuestions"
            >
              {{ $t('button.cancel') }}
            </kdw-btn>
          </v-card>
        </v-dialog>
        <!--------     DIALOG QUESTIONS IN CASE OF QUOTATION BASED    ----------------------------------------->
        <v-dialog
          v-model="dialogQuestions"
          persistent
          width="95%"
        >
          <v-stepper
            v-model="stepper"
            :value="stepper"
          >
            <v-stepper-items
              class="mb-12"
            >
              <v-stepper-content
                v-for="n in questions.question.length"
                :key="`${n}-item`"
                :step="n"
              >
                <v-card
                  class="mb-12"
                >
                  <v-card-title>
                    <v-chip>{{ questions.category }} ({{ n }} / {{ questions.question.length }}) </v-chip> <br>
                  </v-card-title>
                  <h2>{{ question.title }}</h2>
                  <v-card-text>
                    <v-combobox
                      v-if="question.type === 'MultipleSelect'"
                      :id="'multipleSelect' + n"
                      v-model="valueMultipleSelect"
                      :items="itemsSelect"
                      chips
                      clearable
                      center
                      multiple
                    />
                    <v-combobox
                      v-if="question.type === 'OneSelect'"
                      :id="'oneSelect' + n"
                      v-model="valueOneSelect"
                      :items="itemsSelect"
                      chips
                      clearable
                    />
                    <v-text-field
                      v-if="question.type === 'InputText'"
                      :id="'inputText' + n"
                      v-model="valueInputText"
                    />
                    <v-text-field
                      v-if="question.type === 'InputNumber'"
                      :id="'inputNumber' + n"
                      v-model="valueInputText"
                      type="number"
                    />
                  </v-card-text>
                  <v-card-actions>
                    <!-- CANCEL -->
                    <!-- For desktop -->
                    <kdw-btn
                      :id="'cancelQuestionsDesktop' + n"
                      class="hidden-sm-and-down"
                      color="jean"
                      text
                      @click="cancelQuestions"
                    >
                      {{ $t('button.cancel') }}
                    </kdw-btn>
                    <!-- For Mobile -->
                    <kdw-btn
                      :id="'cancelQuestionsMobile' + n"
                      class="hidden-md-and-up"
                      color="jean"
                      min-width="50"
                      text
                      @click="cancelQuestions"
                    >
                      {{ $t('button.cancel') }}
                    </kdw-btn>
                    <v-spacer />
                    <!-- PREVIOUS -->
                    <!-- For desktop -->
                    <kdw-btn
                      v-if="question.hasPrevious"
                      :id="'previousQuestionsDesktop' + n"
                      class="hidden-sm-and-down"
                      color="jean"
                      text
                      @click="previousStep()"
                    >
                      {{ $t('button.previous') }}
                    </kdw-btn>
                    <!-- For Mobile -->
                    <kdw-btn
                      v-if="question.hasPrevious"
                      :id="'previousQuestionsMobile' + n"
                      class="hidden-md-and-up"
                      color="jean"
                      min-width="50"
                      text
                      @click="previousStep()"
                    >
                      {{ $t('button.previous') }}
                    </kdw-btn>
                    <v-spacer />
                    <!-- NEXT OR VALIDATE -->
                    <!-- For desktop -->
                    <kdw-btn
                      :id="'nextQuestionsDesktop' + n"
                      class="hidden-sm-and-down"
                      :color="(question.hasNext) ? 'jean' : 'primary'"
                      right
                      @click="nextStep(n+1)"
                    >
                      {{ (question.hasNext) ? $t('button.nextQuestion') : $t('button.validateQuestion') }}
                    </kdw-btn>
                    <!-- For Mobile -->
                    <kdw-btn
                      :id="'nextQuestionsMobile' + n"
                      class="hidden-md-and-up"
                      :color="(question.hasNext) ? 'jean' : 'primary'"
                      min-width="50"
                      right
                      @click="nextStep(n+1)"
                    >
                      {{ (question.hasNext) ? $t('button.nextQuestion') : $t('button.validateQuestion') }}
                    </kdw-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-dialog>

        <kdw-dialog-booking
          v-model="dialogOnlineBooking"
          :service-id="ServiceIDforOnlineBooking"
          :answer-id="answerId"
          :navigation-object="navigationObjectThisPage"
          @cancelDialogBooking="cancelDialogBooking($event)"
          @oKDialogBookingHero="oKDialogBookingHero($event)"
        />
        <v-responsive
          class="ma-auto"
          max-width="900"
          width="100%"
        >
          <div
            id="hero-line1"
            class="text-center py-6 text-sm-h6 text-md-h5"
          >
            <div v-html="$t('hero.line1.html')" /> <br>
            <div v-html="$t('hero.line2.html')" />
            <br>
            <div
              id="hero-line3"
              class="text-subtitle-2"
              v-text="$t('hero.line3')"
            />
            <div v-html="$t('hero.line4.html')" />
          </div>
        </v-responsive>
        <v-responsive
          class="d-flex my-15 mx-auto"
          height="80%"
          max-width="900"
          width="100%"
        >
          <kdw-postalcode-town
            @localisationData="localisationData($event)"
          />
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <!-- force dark on this component to have white text -->
              <v-autocomplete
                id="Category"
                v-model="searchedId"
                :items="searchCategory"
                dark
                item-text="keyword"
                hide-no-data
                item-value="categoryid"
                auto-select-first
                dense
                prepend-icon="mdi-file-search"
                :label="$t('hero.searchService')"
                @focus="searchCategoryChanged"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <kdw-btn
                id="search_btn"
                @click="search"
              >
                {{ $t('hero.searchBtn') }}
              </kdw-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
            >
              <kdw-general-search-results
                :search-event="searchResultFlag"
                :searched-id="searchedId"
                :localisation="localisation"
                @ResetSearchEvent="resetSearchEvent()"
                @ServiceSelected="ServiceSelected($event)"
                @NoServiceFound="NoServiceFound()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-card class="font-weight-bold ml-6 mr-6 my-4">
              <span class="ma-3">
                {{ $t('hero.pleaseVisit') }}
                <a
                  href="https://kidiwiServices.com/fr/web/annabel"
                  target="_blank"
                > Annabel</a>,
                <a
                  href="https://kidiwiServices.com/fr/web/dumasmotoculture"
                  target="_blank"
                > Dumas Motoculture</a>,
                <a
                  href="https://kidiwiServices.com/fr/web/winetastingevents"
                  target="_blank"
                > Wine Tasting Events</a>,
                <a
                  href="https://kidiwiServices.com/fr/web/electrobat"
                  target="_blank"
                > Electrobat</a>, {{ $t('hero.nodescription') }}
              </span>
            </v-card>
          </v-row>
        </v-responsive>
      </v-container>
    </base-img>
  </section>
</template>

<script>
  import { Vue, Component, Watch, Provide } from 'vue-property-decorator'
  // import { fetchTownfromPostalCode } from '@/services/PostalCode'
  import { fetchSearchTable } from '@/services/Search'
  // import { createPostalData } from '@/services/DevPostalCode'
  import {
    pickItem as CpickItem,
    pickItemFixedPriceBookingFromHero as CpickItemFixedPriceBookingFromHero,
    cancelQuestions as CcancelQuestions,
    getAnswerFromUser as CgetAnswerFromUser,
    setAnswerForUser as CsetAnswerForUser,
    nextStep as CnextStep,
    previousStep as CpreviousStep,
    // getFinalReport as CgetFinalReport,
    persistQuestions as CpersistQuestions,
    clearPersistQuestions as CclearPersistQuestions,
    isPersistQuestionsEmpty as CisPersistQuestionsEmpty,
    noServiceBuildRequestBasedQuestion,
  } from '@/controllers/Questions'
  import { ANONYMOUS, ENDUSER, SERVICEPROVIDER, getUserType, onUserTypeChange } from '@/services/User'
  import { navigate, verifyLocale } from '@/helpers/Navigation'
  import { getBackgroundPicture } from '@/helpers/Background'
  import { Logger } from 'aws-amplify'
  // import i18n from '@/plugins/i18n.js'

  const logger = new Logger('MainPageHero')

  @Component({ name: 'SectionHero' })
  class HeroAccount extends Vue {
    // Data used to manage the booking:
    ServiceIDforOnlineBooking = ''
    answerId = ''

    navigationObjectThisPage = null
    // Flag to display the search results
    searchResultFlag = false
    // Postcode entered by the end user
    // postcode = ''
    // ID of the town selected by the end user
    // townNameNumber = ''
    // Range of the search
    // rayon = '30'
    // Category ID selected by the end user
    searchedId = ''
    // Towns : number: j, townName: codePost.townName, id: codePost.id, latitude: codePost.latitude, longitude: codePost.longitude
    // towns = []
    // searchCategory: keyword: category.keywords[i], categoryid: category.id + ',' + i
    searchCategory = []
    // foundServices: To be specified
    foundServices = []
    // pictureName = '/background/background000.jpg'
    localisation = { found: false }
    // header of the table of result
    /*
    headers = [{ text: this.$i18n.t('hero.serviceTable'), align: 'start', sortable: true, value: 'name' },
              { text: this.$i18n.t('hero.descriptionTable'), value: 'serviceDescriptionShort' },
              { text: this.$i18n.t('hero.priceTable'), value: 'ServicePrice' },
              { text: this.$i18n.t('hero.durationTable'), value: 'serviceDuration' },
              { text: this.$i18n.t('hero.categoryTable'), value: 'category' },
              { text: this.$i18n.t('hero.subcategoryTable'), value: 'subCategory' },
              { text: this.$i18n.t('hero.whereTable'), value: 'townName' },
              { text: this.$i18n.t('hero.actionTable'), value: 'actions', sortable: false },
    ]
    */
    // This is an equivalent of @Computed, so everytime the text value is changing, this is updated automatically (translation case here)
    get headers () {
      return [{ text: this.$i18n.t('hero.serviceTable'), align: 'start', sortable: true, value: 'name' },
              { text: this.$i18n.t('hero.descriptionTable'), value: 'serviceDescriptionShort' },
              { text: this.$i18n.t('hero.priceTable'), value: 'ServicePrice' },
              { text: this.$i18n.t('hero.durationTable'), value: 'serviceDuration' },
              { text: this.$i18n.t('hero.categoryTable'), value: 'category' },
              { text: this.$i18n.t('hero.subcategoryTable'), value: 'subCategory' },
              { text: this.$i18n.t('hero.whereTable'), value: 'townName' },
              { text: this.$i18n.t('hero.actionTable'), value: 'actions', sortable: false },
      ]
    }

    //  data for the dialog mngt
    editedIndex = -1

    pickedItem = {
      name: '',
    }

    // Mngt of the question in the different steps
    dialogQuestions = false
    dialogOnlineBooking = false
    bookingOnlineFromHero = false // To know if this booking is done from Hero page
    dialogAuth = false
    stepper = 0 // stepper index starts at 0
    defaultItem = { question: [], category: '', subCategory: '' } // default
    questions = { question: [], category: '', subCategory: '' } // all questions, default value to pre-render
    question = {} // current question
    itemsSelect = [] // ?
    nbStep = [Array(3).fill(0)] // total number of steps

    valueMultipleSelect = []
    valueOneSelect = ''
    valueInputText = ''
    answers = null // all answers
    finalReport = null

    // Last step - authenticator
    ENDUSER = ENDUSER
    ANONYMOUS = ANONYMOUS
    SERVICEPROVIDER = SERVICEPROVIDER
    userType = null
    signInConfig = {}
    signupFormFields = []
    navigateNext = 'HOME'
    // Store a locale to check if the languge have switched.
    locale = 'fr'

    // To avoid to change the background display picture
    backgroundPictureReference = '' // getBackground(this.$options.name)

    // Watch definition:

    @Watch('dialogQuestions')
    dialogQuestions1 (val) {
      val || this.close()
    }

    @Watch('stepper')
    stepper1 (val) {
      this.nextStep(val)
    }

    @Watch('userType')
    userType1 (newV, oldV) {
      logger.info(`changed userType from ${oldV} to ${newV}`)
    }

    @Provide() theme = { isDark: false }

    // @Computed --> get
    get minHeight () {
      const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'
      // console.log('height:', height)
      return `calc(${height} - ${this.$vuetify.application.top}px)`
    }

    created () {
      // console.log('pictureName')
      // console.log(this.getBackgroundPictureName())
      this.navigationObjectThisPage = this
      this.ANONYMOUS = ANONYMOUS
      this.ENDUSER = ENDUSER
      this.SERVICEPROVIDER = SERVICEPROVIDER
      this.locale = this.$i18n.locale
      this.userType = getUserType()
      this.unsubscribeUserType = onUserTypeChange((newUserType) => {
        logger.info('changing userType ', newUserType)
        this.userType = newUserType
        if (this.userType === ENDUSER) {
          // console.log('hero - Seen as a User - Cpersist is run')
          CpersistQuestions()
        } else if (this.userType === SERVICEPROVIDER) {
          if (CisPersistQuestionsEmpty() === false) {
            // console.log('hero - Seen as a SP - Cpersist is not run & clear the array')
            CclearPersistQuestions()
            // this.$root.kdwsnackbar.alert(this.$i18n.t('hero.alertAuthenticatedNotEU'), -1)
            // we have to keep an alert if not the snackbar is not visible.
            alert(this.$i18n.t('hero.alertAuthenticatedNotEU'))
          }
          navigate(this, 'ProHome') // SP goes Home
        }
        // ignore going to null or Anonymous ?
        // console.log('hero - Seen as a ?? - Cpersist is not run')
      })
    }

    cancelDialogBooking () {
      const localItem = {
        name: '',
      }
      this.pickedItem = Object.assign({}, localItem)
      this.bookingOnlineFromHero = false
    }

    oKDialogBookingHero (appointmentData) {
      // console.log('Appointment Data:')
      // console.log(appointmentData)
      if (this.pickedItem.isFixedPrice && this.pickedItem.onlineBooking) {
        CpickItemFixedPriceBookingFromHero(this, this.pickedItem, appointmentData)
        // We reset the selection.
        this.cancelDialogBooking()
      }
    }

    getBackgroundPicture () {
      // We check if we already generated this picture name in this session or not
      if (this.backgroundPictureReference !== '') return this.backgroundPictureReference
      this.backgroundPictureReference = getBackgroundPicture()
      return this.backgroundPictureReference
    }

    // We receive the data from the selcetion of the town and the radius component
    localisationData (event) {
      this.localisation = event
      // console.log('parent this.localisation')
      // console.log(this.localisation)
    }

    // The end user have selected this service TODO
    async ServiceSelected (serviceSelected) {
      // console.log('serviceSelected')
      // console.log(serviceSelected)
      // We need to check if we are not logged as SP here first.
      const userType = await getUserType()
      console.log('userType', userType)
      if (userType === SERVICEPROVIDER) {
        // We need to handle an error here or we need to prevent it before, which is what I have implemented, so normally we should never pass here!!!
        this.$root.kdwsnackbar.alert(this.$i18n.t('hero.alertDisconnectFromSPRole'))
        return
      }
      // Then we can handle the pickItem management
      return CpickItem(this, serviceSelected, this.localisation.id, this.$i18n.locale)
    }

    NoServiceFound () {
      // console.log('NoServiceFound')
      const newLocale = verifyLocale(this.$i18n.locale)
      var headers = this.searchedId.split(',')
      // console.log('headers')
      // console.log(headers)
      // console.log('this.searchCategory')
      // console.log(this.searchCategory)
      noServiceBuildRequestBasedQuestion(this, headers[0], this.searchCategory[headers[1] - 1].subCatId, this.localisation.id, this.searchCategory[headers[1] - 1].keyword, newLocale)
    }

    search () {
      // We verify id the end have selected all the required item
      if ((this.localisation.found !== false) && (this.searchedId !== '')) {
        this.searchResultFlag = true
        /*
        // keep only a part of the ID as it was use to make the list working
        // --> var headers = this.searchedId.split(',')
        // clear the found service table
        // --> this.foundServices.splice(0, this.foundServices.length)
        // Perform the search
        // --> servicesfromOnePostalCodeRayon(this.localisation.latitude, this.localisation.longitude, this.localisation.radius, headers[0], this.foundServices)
        // -->   .then(() => {
        // -->     this.searchResultFlag = true
            // console.log('this.foundServices')
            // console.log(this.foundServices)
            // console.log('this.searchCategory')
            // console.log(this.searchCategory)
            // console.log('this.searchedId')
            // console.log(this.searchedId)
            // console.log('headers')
            // console.log(headers)
        // -->       if (this.foundServices.length === 0) {
              // No service are found, we are proposing to record the request.
              // console.log('----> locales', this.$i18n.locale)
        // -->         const newLocale = verifyLocale(this.$i18n.locale)
              // console.log('----> new locales', newLocale)
              // console.log(this)
              // console.log('headers[0]:', headers[0])
              // console.log('subCatId', this.searchCategory[headers[1] - 1].subCatId)
              // console.log('town:', this.towns[this.townNameNumber].id)
              // console.log('keyword', this.searchCategory[headers[1] - 1].keyword)
              // console.log('---------')
        // -->           noServiceBuildRequestBasedQuestion(this, headers[0], this.searchCategory[headers[1] - 1].subCatId, this.localisation.id, this.searchCategory[headers[1] - 1].keyword, newLocale)
              // .then(() => {
              // console.log('we pass here')
              // console.log(this.searchCategory)
              // this.questions.category = this.searchCategory[headers[1] - 1].keyword
              // this.questions.subCategory = item.subCategory
              // })
              // .catch(err => logger.error(err))
        // -->       }
        // -->     })
        // -->     .catch(err => logger.error(err))
        */
      } else {
        this.$root.kdwsnackbar.alert(this.$i18n.t('hero.searchAlert'), 4000)
      }
    }

    searchCategoryChanged () {
      if ((this.searchCategory.length === 0) || (this.locale !== this.$i18n.locale)) {
        this.locale = this.$i18n.locale
        fetchSearchTable(this.searchCategory)
      }
    }

    cancelQuestions () {
      return CcancelQuestions(this)
    }

    getAnswerFromUser () {
      return CgetAnswerFromUser(this)
    }

    setAnswerForUser (value = null) {
      return CsetAnswerForUser(this, value)
    }

    nextStep (n = null) {
      return CnextStep(this, n)
    }

    previousStep (n = null) {
      return CpreviousStep(this, n)
    }

    close () {
      this.$nextTick(() => {
        this.editedItem = this.defaultItem
      })
    }

    resetSearchEvent () {
      this.searchResultFlag = false
    }
  }

  export default HeroAccount
</script>
